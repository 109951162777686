<template>
  <div >
    <!-- <Navbar />
    <Sidebar /> -->
    <section
      class="main"
    >
      <DressListingg
        @click="dressId"
      />
      <ColorListingg
        :material-id="dressIdShow"
        :create-order="true"
        @click="colorId"
      />
      <ProductsTablee
        :material-id="dressIdShow"
        :color-id="colorIdShow"
        :create-order="true"
        @addArticleItem="addArticleItem"
        @inside="inside"
      />
      <InsideProductt
        :added-article="addedArticle"
        :product-mat-obj="productMatObj"
        :create-order="true"
      />
    </section>

  </div>

</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/sidebars/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import DressListingg from '@/components/productsInStock/DressListingg.vue'
import ColorListingg from '@/components/productsInStock/ColorListingg.vue'
import ProductsTablee from '@/components/productsInStock/ProductsTablee.vue'
import InsideProductt from '@/components/productsInStock/InsideProductt.vue'
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    DressListingg,
    ColorListingg,
    ProductsTablee,
    InsideProductt,
  },
  data() {
    return {
      dressIdShow: '',
      colorIdShow: '',
      productMatObj: {},
      addedArticle: [],
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  watch: {
    // eslint-disable-next-line func-names
    inside(item) {
      this.productMatObj = item
    },
  },
  methods: {
    addArticleItem(item) {
      this.addedArticle.push(item)
    },
    inside(item) {
      this.productMatObj = item
    },
    dressId(id) {
      this.dressIdShow = id
    },
    colorId(id) {
      this.colorIdShow = id
    },
  },
}
</script>

<style scoped lang="scss">

@media screen and (min-width: 1340px) and (max-width: 1600px){
  .main{
    padding: 75px 15px 15px 90px;
  }
}
@media screen and (max-width: 1340px){
  .main{
    padding: 75px 15px 15px 90px;
    display: flex;
    flex-wrap: wrap !important;
    justify-content: flex-start
}
}
@media screen and (max-width: 1400px) {
  .flex{
   width: 40% !important;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 0 0.75rem;
    display: flex;
    flex-wrap: wrap !important;
    justify-content: flex-start
  }
}
@media screen and (max-width: 896px) {
  .main {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
</style>
