<template>
  <div
    :class="
      $route.path.includes('products') ? 'products-listing__wrapper2' : 'products-listing__wrapper'
    "
    style="width: 18%; display: flex; flex-direction: column; align-items: flex-start"
    :style="
      $route.path.includes('products')
        ? 'margin-top: 64px; width: 18%; display: flex; flex-direction: column; align-items: flex-start;'
        : 'margin-top: 19px; width: 20%; display: flex; flex-direction: column; align-items: flex-start;'
    "
  >
    <button
      v-if="$route.path.includes('products')"
      v-b-modal.modal-add-size
      class="button"
      style="border-radius: 6px"
      :disabled="!colorId"
      :class="{'button--disabled': (!colorId && getDressesSize.length == 0)}"
      @click="getMaterialVByMandP"
    >
      <b-icon-plus />
      {{ $t('AddSize') }}
    </button>
    <div
      class="table__header"
      style="margin-bottom: 15px !important; margin-top: -14px"
    >
      <button
        v-if="$route.path.includes('product-in-stock')"
        v-b-modal.add-custom-order-passive-stock
        class="button"
        style="border-radius: 6px; width: auto; position: relative; width: 210px"
        :disabled="(!colorId && getDressesSize.length == 0)"
        :class="{'button--disabled': (!colorId && getDressesSize.length == 0)}"
        @click="rr"
      >
        <img
          src="../../assets/images/plus-circle.svg"
          alt="plus"
        >
        {{ $t('AddInStock') }}
        <span
          v-if="counter != 0"
          style="font-size: 13px; background: red; border-radius: 50%; width: 20px; height: 20px; position: absolute; top: -5px; right: -9px"
        >{{ counter == 0 ? '' : counter }}</span>
      </button>
    </div>
    <div
      style="width: 100%;  padding-top: 16px"
      :class="{'button--disabled': getDressesSize.length == 0}"
      :style="$route.path.includes('products') ? 'margin-top:-3px;' : 'margin-top: -17px'"
    >
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('ArticleName') }}
            </th>
            <th style="padding: 10px">
              {{ $t('Size') }}
            </th>

            <th
              v-if="$route.path.includes('products')"
              colspan="2"
            >
              {{ $t('Edit') }}
            </th>
            <th
              v-if="$route.path.includes('product-in-stock')"
            />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in getDressesSize"
            :key="item.inventoryProductId"
            class="hover-row"
            style="cursor: pointer"
            :style="classNameCh === item.inventoryProductId ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="matProps(item)"
          >
            <td>
              {{ item.articleNumber }}
            </td>
            <td>
              {{ item.size }}
            </td>
            <td
              v-if="$route.path.includes('products')"
              v-b-modal.modal-edit-size
              :style="
                $route.path.includes('products')
                  ? 'padding: 10px; text-align: left; cursor: pointer'
                  : 'padding: 5px'
              "
              @click="edit(item)"
            >
              <b-icon-pencil />
            </td>
            <td
              v-if="$route.path.includes('product-in-stock')"
              :style="
                $route.path.includes('products')
                  ? 'padding: 10px; text-align: left; cursor: pointer'
                  : 'padding: 5px'
              "
            >
              <b-button
                class="buttonSubmit"
                size="sm"
                style="width: 140px"
                @click="AddToCustom(item)"
              >
                <span v-if="item.added">{{ $t('Added') }}</span>
                <span v-else> {{ $t("AddToS") }}</span>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddSizee
      :materials="materialId"
      :measure-item="measureId"
      :color-data="colorId"
      @variantMaterialData="variantMaterialData"
    />
    <EditSizee
      :color-data="colorId"
      :materials="materialId"
      :measure-item="measureId"
      :e-size="eSize"
      :materials-article-num="getMaterialsByArticleNum.productSingleMaterialVariantDtos"
      @updateSize="editSize"
    />
    <CreateOrderModall
      :added-article="rows"
      :materials="getMaterialsByArticleNum"
      :article-number="articleeNumber"
      @createOrder="createOrderInStore"
      @incrementCounter="increment"
      @decrementCounter="decrement"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CreateOrderModall from '@/components/productsInStock/modals/CreateOrderModalPassiveStock.vue';
import AddSizee from '@/components/products/modals/AddSize.vue';
import EditSizee from '@/components/products/modals/EditSize.vue';

export default {
  components: {
    AddSizee,
    CreateOrderModall,
    EditSizee,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    materialId: {
      type: [String, Object],
    },
    createOrder: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    colorId: {
      type: [String, Object],
    },
    measureId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      initialId: '',
      materialPropId: '',
      status: true,
      size: '',
      articleeNumber: null,
      eSize: {
        size: '',
      },
      classNameCh: '',
      searchTerm: '',
      rows: [],
      materialObject: {},
      editMat: {},
      artNum: '',
      invProdId: '',
      counter: 0,
    };
  },
  computed: {
    ...mapGetters([
      'getDressesSize',
      'getCustomOrders',
      'getMaterialsByArticleNum',
      'getResetStatus',
      'getDressColors',
    ]),
  },
  watch: {
    getResetStatus(value) {
      if (value == true) { this.rows = []; this.counter = 0; this.resetCustomOrders2() }
    },
  },
  methods: {
    ...mapActions([
      'inventoryProductSizeFromColorId',
      'getInventoryProductsByColorId',
      'productsByArticleNumber',
      'addInventoryProductSize',
      'editInventoryProductSize',
      'addOrderInStore',
      'resetCustomOrders',
      'resetCustomOrders2',
      'getMaterialVariantsToMDCPagination',
      'resetDressColors',
      'resetSizes',
      'resetProductsByArticleNumber',
      'addProductInStock',
    ]),
    edit(item) {
      this.eSize.size = item.size;
      this.invProdId = item.inventoryProductId;
      this.getMaterialVByMandP()
    },
    async editSize(obj) {
      await this.editInventoryProductSize({
        inventoryProductId: this.invProdId,
        object: obj,
        successCallback: () => {
          this.inventoryProductSizeFromColorId(this.colorId.mainDressCategoryColorId);
          this.productsByArticleNumber(this.artNum);
        },
      });
    },
    rr() {
      // this.loadOrderTypes()
      this.getMaterialVByMandP()
      if (this.getResetStatus == true) {
        this.rows = [];
      }
    },
    increment() {
      this.counter++;
    },
    decrement() {
      this.counter--;
    },
    async AddToCustom(item) {
      await this.productsByArticleNumber(item.articleNumber);
      const t = {
        itemi: item, pickUpDate: '', filledPickUpDate: true, materials: this.getMaterialsByArticleNum,
      }
      console.log(t)
      this.rows.push(t);
      this.counter++;
      this.resetCustomOrders2();
    },
    // async AddToCustom2(item) {
    //   await this.productsByArticleNumber(item.articleNumber);
    //   // eslint-disable-next-line no-restricted-syntax
    //   for (let i = 0; i < this.rows.length; i++) {
    //     if (this.rows[i].inventoryProductId == item.inventoryProductId) {
    //       return;
    //     }
    //     this.rows.push(item);
    //   }
    //   // this.rows.push(item);
    //   this.resetCustomOrders2();
    // },
    async createOrderInStore(obj) {
      await this.addProductInStock(obj);
      this.resetDressColors()
      this.resetSizes()
      this.resetProductsByArticleNumber()
    },

    async variantMaterialData(filterObj) {
      // eslint-disable-next-line no-unused-vars
      const addInvertoryObj = {
        mainDressCategoryId: this.materialId.id,
        mainDressCategoryColorId: this.colorId.mainDressCategoryColorId,
        ...filterObj,
      };
      await this.addInventoryProductSize({
        object: addInvertoryObj,
        successCallback: () => {
          this.inventoryProductSizeFromColorId(addInvertoryObj.mainDressCategoryColorId);
        },
      });
    },

    async matProps(mat) {
      // this.$emit('inside', mat)
      this.articleeNumber = mat.articleNumber
      this.materialPropId = mat.materialId;
      this.classNameCh = mat.inventoryProductId;
      this.size = mat.size;
      this.artNum = mat.articleNumber;
      await this.productsByArticleNumber(mat.articleNumber);
    },
    async getMaterialVByMandP() {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      });
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      });
    },
  },
};
</script>

<style scoped lang="scss">

.team_table td {
  padding: 11px 9px;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.products-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
}
.button {
  margin-bottom: 15px !important;
  width: 195.22px;
  background: #FF274F !important;
    color: #f4f1ed;
    border-color: transparent;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

.small{
  background: #FF274F;
}

tr:last-child {
  border-bottom: none !important;
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .products-listing__wrapper {
    width: 25% !important;
  }
}
@media screen and (max-width: 1400px) {
  .products-listing__wrapper {
    width: 100% !important;
    margin-right: 5px;
    margin-left: 5px;
  }

  // }
  .products-listing__wrapper2 {
    width: 100% !important;
    margin-left: 5px;
    margin-right: 5px;

    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .products-listing__wrapper {
    width: 100% !important;
  }
}

@media screen and (max-width: 896px) {
  .products-listing__wrapper {
    width: auto !important;
    flex-basis: 30%;
  }
}
</style>
