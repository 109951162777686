<template>
  <div class="standard-order-con">
    <h4>{{ dress.itemi.articleNumber }}</h4>
    <div
      class="modal-card__wrapper"
      style="display:flex; flex-direction: row;"
    >
      <div style="display: flex; flex-direction: row;">
        <div style="display: flex; flex-direction: column;">
          <b-form-group
            :label="`${$t('ReleaseDate')}:`"
            style="display: inline-block; background: #FF274F ; border-radius: 7px; padding: 8px; color: white; "
          >
            <b-input
              v-model="dress.pickUpDate"
              type="date"
            />
          </b-form-group>
          <p
            v-if="!dress.filledPickUpDate"
            style="color:red; margin: 5px; display: inline-flex;"
          >
            {{ $t('PickUpReq') }}
          </p>
        </div>
      </div>
      <div style="width: 100%">
        <b-form-group
          :label="`${$t('Price')}:`"
          style="display: inline-block; background: #FF274F ; border-radius: 7px; padding: 8px; color: white; width: 24%;"
        >
          <div style="display: flex; justify-content: flex-start; align-items: center;">
            <b-input
              v-model.number="dress.itemi.price"
              type="number"
              style="width: 100%; margin-right: 5px;"
            />
            {{ dress.itemi.currency }}
          </div>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['dress', 'submitClicked'],
  data() {
    return {
      index: 0,
    }
  },
  watch: {
    dress: {
      handler(newValue) {
        if (this.submitClicked == true) {
          if (newValue.pickUpDate == '') {
            this.dress.filledPickUpDate = false
          } else {
            this.dress.filledPickUpDate = true
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    removeProbeDate(index) {
      this.dress.probeDates.splice(index, 1);
    },
    addNewDate() {
      this.dress.probeDates.push(
        {
          probeName: `Fitting Date ${this.index += 1}`,
          dateOfProbe: '',
          notes: null,
        },
      );
    },

  },
}
</script>

<style scoped lang="scss">
.standard-order-con {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
}
</style>
