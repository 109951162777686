<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div
    :class="$route.path.includes('products') ? 'mats-listing__wrapper2' : 'mats-listing__wrapper'"
    :style="$route.path.includes('products') ? 'display: flex; flex-direction: column; align-items: start; width: auto; margin-top: 12px;' : 'width: auto; margin-top: 0px;'"
    @click="removeLists"
  >
    <div
      style="display: flex"
      :style="$route.path.includes('products') ? '' : 'padding-bottom: 14px;'"
    >
      <div>
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchDress == ''"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchDress != '' && !noSearch"
              class="search1"
              style="position: absolute;right: 20px; top: 12px; cursor: pointer"
              @click="searchDress = ''"
            />
            <input
              v-model="searchDress"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('SearchByDress')"
              :style="getFilteredDresses.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilteredDresses.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilteredDresses.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilteredDresses"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result); noSearch = false"
            >
              <p style="margin: 0">
                {{ result }}
              </p>
            </div>
            <!-- <div
              v-if="getFilteredDresses.length == 0"
              class="search-item"
            >
              <p>Sorry. No Results.</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="$route.path.includes('products')"
      v-b-modal.modal-add-dress
      class="button"
      style="border-radius:6px"
    >
      <b-icon-plus />
      {{ $t('AddDress') }}
    </button>
    <div
      class="tt"
      :style="$route.path.includes('products') ? 'width:100% !important;  padding-top: 16px; margin-top:-3px;' : ' padding-top: 16px; margin-top: 0px;'"
    >
      <table
        responsive
        class="team_table"
      >
        <thead>
          <tr>
            <th :style="$route.path.includes('products') ? 'padding: 10px; border-radius: 0px 0px 0px 0px' : 'padding: 10px; border-radius: 0px 0px 0px 0px'">
              {{ $t('Dress') }}
            </th>
            <th
              v-if="$route.path.includes('products')"
              colspan="2"
            >
              {{ $t('Edit') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="dress in getDresses"
            :key="dress.id"
            class=" hover-row"
            style="cursor: pointer"
            :style="classNameCh === dress.id ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="matProps(dress)"
          >
            <td style="padding: 10px">
              {{ dress.name }}
            </td>
            <td
              v-if="$route.path.includes('products')"
              v-b-modal.modal-edit-dress
              style="cursor: pointer"
              @click="edit(dress)"
            >
              <b-icon-pencil />
            </td>
            <td
              v-if="$route.path.includes('products')"
              v-b-modal.modal-delete-dress
              @click="getDeleteId(dress.id, dress.name)"
            >
              <b-icon-trash />
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-if="getTotalItemsForDresses > 15"
          v-model="page"
          :total-rows="getTotalItemsForDresses"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          size="md"
          prev-class="prev-itemiii"
          next-class="next-itemiii"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>

    <AddDressModal @addDresses="addDress" />
    <EditDressModal
      :dress="dress"
      @updateDress="editDress"
    />
    <DeleteDressModal
      :name="dName"
      @deleteDressM="deleteD"
    />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from 'vuex';
import AddDressModal from '@/components/products/modals/AddDressModal.vue';
import EditDressModal from '@/components/products/modals/EditDressModal.vue';
import DeleteDressModal from '@/components/products/modals/DeleteDressModal.vue';



export default {
  components: {
    AddDressModal,
    EditDressModal,
    DeleteDressModal,
  },
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      toggleSarch: false,
      noSearch: false,
      searchDress: '',
      // initialId: '',
      classNameCh: '',
      deleteDressId: '',
      dName: '',
      dress: {
        created: '',
        id: '',
        name: '',
      },
      page: 1,
      pageSize: 15,
      arrowCounter: 0,
      enterPressed: false,
    };
  },
  computed: {
    ...mapGetters(['getFilteredDresses', 'getDresses', 'getCurrentPage', 'getTotalItemsForDresses']),
    // activeClass() {
    //   if (this.item.name === this.classNameCh) {
    //     return 'active';
    //   }
    //   return '';
    // },
  },
  watch: {

    // eslint-disable-next-line func-names
    // initialId() {
    //   this.immediateId(this.initialId);
    // },
    page(value) {
      this.loadDressesPagination({
        pageNumber: value,
        pageSize: this.pageSize,
        mdcName: this.searchDress,
      });
    },
    searchDress(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadDressesPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
            mdcName: this.searchDress,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.filteredDressesByName(value);
          this.noSearch = true
        }
      }, 500);
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions(['filteredDressesByName',
      'loadDressesPagination',
      'addMainDressCategory',
      'editMainDressCategory',
      'loadDressColorsById',
      'resetProductsByArticleNumber',
      'resetSizes',
      'resetCustomOrders',
      'deleteMainDress',
      'changeLoadingtoTrue',
      'resetFilteredDresses',
      'resetDressColors',
    ]),
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)


        await this.loadDressesPagination({
          pageNumber: this.page,
          pageSize: this.pageSize,
          mdcName: this.searchDress,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredDresses.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredDresses = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilteredDresses[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    edit(item) {
      this.dress.created = item.created;
      this.dress.id = item.id
      this.dress.name = item.name
      this.$bvModal.show('modal-edit-dress');
    },
    async editDress(obj) {
      await this.editMainDressCategory({
        object: obj,
        successCallback: () => {
          this.loadDressesPagination({ pageNumber: this.getCurrentPage, pageSize: this.pageSize, mdcName: this.searchDress });
        },
      })
    },

    async addDress(obj) {
      await this.addMainDressCategory({
        object: obj,
        successCallback: () => {
          this.loadDressesPagination({ pageNumber: 1, pageSize: this.pageSize, mdcName: this.searchDress });
        },
      });
    },
    getDeleteId(id, name) {
      this.deleteDressId = id
      this.dName = name
    },
    async deleteD() {
      await this.deleteMainDress({
        id: this.deleteDressId,
        successCallback: () => {
          this.loadDressesPagination({ pageNumber: this.getCurrentPage, pageSize: this.pageSize, mdcName: this.searchDress });
          this.resetDressColors()
          this.resetSizes()
          this.resetProductsByArticleNumber()
        },
      })
    },


    // immediateId(e) {
    //   this.$emit('click', e);
    // },
    matProps(dress) {
      this.resetProductsByArticleNumber();
      this.resetSizes();
      this.$emit('click', dress);
      this.classNameCh = dress.id;
      try {
        // this.changeLoadingtoTrue(false)

        this.loadDressColorsById({ mainDressCategoryId: dress.id, pageNumber: 1, pageSize: this.pageSize }).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true)
        })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
      // this.resetCustomOrders()
    },
    async searchByOn(value) {
      this.searchDress = value
      this.noSearch = false
      await this.loadDressesPagination({
        pageNumber: this.page,
        pageSize: this.pageSize,
        mdcName: this.searchDress,
      })
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredDresses();
    },
  },
};
</script>

<style scoped lang="scss">

.team_table td {
  padding: 16px 9px !important;
}

.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.mats-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
}
.button {
  margin-bottom: 15px !important;
  margin-top: 14px;
  width: 195px;

}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}
.justify-content-end{
  justify-content: flex-start !important;
}
// .team_table .tr{
//   border-radius: 10px 10px 10px 10px !important;
// }
tr:last-child {
  border-bottom: none !important;
}
 @media screen and (max-width: 1823px) {

  .mats-listing__wrapper2{
    width: 250px !important;
  }
  .tt{
    width: 100%;
  }

 }
@media screen and (max-width: 1400px) {
.mats-listing__wrapper{
  width: 28% !important;
  margin-top: 0px !important;
  margin-right: 5px;
.tt{
  width: 100%;
}
}
.mats-listing__wrapper2{
  width: 200px !important;
   button{
width: 150px;
font-size: 13px;
height: 36px;
   }
.tt{
  width: 200px;
}
 }
 }

.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 1200px) {
  .mats-listing__wrapper {
    width: 360px !important;
    margin-top: 0px !important;
  }
  .mats-listing__wrapper .tt {
    width: 360px !important;
  }
}
@media screen and (max-width: 896px) {
  .mats-listing__wrapper {
    width: auto !important;
    flex-basis: 49%;
  }
  .tt {
    margin-right: 0 !important;
    width: auto !important;
  }
}
.is-active {
  background-color: #dedede;
}
</style>
